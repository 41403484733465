define("discourse/plugins/unit-staff-system/discourse/components/platoon-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "bootbox", "I18n"], function (_exports, _component, _tracking, _object, _ajax, _ajaxError, _bootbox, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let PlatoonEditor = _exports.default = (_class = class PlatoonEditor extends _component.default {
    constructor() {
      super(...arguments);
      /*
      args:
      - platoon
      - onChange(squad)
      - onDelete(squad)
      - onExit()
       */
      _initializerDefineProperty(this, "platoon", _descriptor, this);
      // Create squad properties
      _defineProperty(this, "createSquadName", "");
      _defineProperty(this, "createSquadDisabled", false);
      this.platoon = this.args.platoon;
      this.squad = this.args.squad;
    }
    get selectableSquadTypes() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.types.training"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.types.permanent"),
        value: false
      }];
    }
    get selectableSquadReserves() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.true"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.false"),
        value: false
      }];
    }
    updatePlatoon(platoon) {
      this.platoon = platoon;
      this.args.onChange(platoon);
    }
    updatePlatoonRemote(requestType, urlAction) {
      (0, _ajax.ajax)(`/uss/roster/platoons/${this.platoon.id}${urlAction}`, {
        type: requestType
      }).then(platoon => {
        this.updatePlatoon(platoon);
      }).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
        throw err;
      });
    }
    onExit() {
      this.args.onExit();
    }
    onCreateSquad() {
      const name = this.createSquadName.trim();
      this.set('createSquadName', '');
      this.set('createSquadDisabled', true);
      this.updatePlatoonRemote("POST", `/squads?${new URLSearchParams({
        name
      })}`);
      (0, _ajax.ajax)(`/uss/roster/platoons/${this.platoon_id}/squads?${new URLSearchParams({
        name
      })}`, {
        type: "POST"
      }).then(newSquad => {
        this.platoon.squads.pushObject(newSquad);
        this.updatePlatoon(this.platoon);
      }).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
        throw err;
      }).finally(() => {
        this.set('createSquadDisabled', false);
      });
    }
    onChangePlatoonName(name) {
      this.updatePlatoonRemote("PUT", `?${new URLSearchParams({
        name
      })}`);
    }
    onChangePlatoonLeader(username) {
      this.updatePlatoonRemote("PUT", `?${new URLSearchParams({
        leader_username: username
      })}`);
    }
    onChangePlatoonAssistant(username) {
      this.updatePlatoonRemote("PUT", `?${new URLSearchParams({
        assistant_username: username
      })}`);
    }
    onDeletePlatoon() {
      _bootbox.default.confirm(_I18n.default.t("unit_staff_system.admin.platoons.editor.delete.confirmation.title"), _I18n.default.t("unit_staff_system.admin.platoons.editor.delete.confirmation.delete"), _I18n.default.t("unit_staff_system.admin.platoons.editor.delete.confirmation.cancel"), cancelled => {
        if (!cancelled) {
          (0, _ajax.ajax)("/uss/roster/platoons/" + this.platoon.id, {
            type: "DELETE"
          }).then(() => {
            this.args.onDelete(this.platoon);
          }).catch(err => {
            (0, _ajaxError.popupAjaxError)(err);
            throw err;
          });
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "platoon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onExit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onExit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCreateSquad", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCreateSquad"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangePlatoonName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangePlatoonName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangePlatoonLeader", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangePlatoonLeader"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangePlatoonAssistant", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangePlatoonAssistant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDeletePlatoon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDeletePlatoon"), _class.prototype)), _class);
  ;
});